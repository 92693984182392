
.header {
    background-color: #2075b8 !important;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1;
    border-bottom: 2px solid white;


}

.header .logo img {
    height: 45px;
    width: 90px;
}

.nav-link {
    color: white;
    font-weight: 390;
    font-size: 13px;
    margin-left: 4px;
    line-height: 1.75;
    letter-spacing: 1.1px;
}

.nav-link:hover {

    color: black;
}

.header .btn {
    justify-content: end;
    background-color: #5f9ac7;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
}


 .banner {
     position: relative;
     overflow: hidden;
     height: 100vh;
 }

 .banner-image {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-image: url('assets/banner.png');
     object-fit: cover;
     background-position: center;
 }

.banner .texts{
    position: absolute;
    bottom: 265px;
    left: 115px;


}

.btext {
    font-family: "Open Sans", sans-serif !important;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: 1.1px;
}

.btext span {
    display: inline-block;
    font-size: 45px;
    color: #2075b8;
    animation: flip 3s infinite;
    animation-delay: calc(.4s * var(--i))
}

@keyframes flip {

    0%,
    80% {
        transform: rotateY(340deg)
    }
}

.btext span {
    --i: 1;
}

.btext span:nth-child(2) {
    --i: 2;
}

.btext span:nth-child(3) {
    --i: 3;
}

.btext span:nth-child(4) {
    --i: 4;
}

.btext span:nth-child(5) {
    --i: 5;
}

.btext span:nth-child(6) {
    --i: 6;
}

.btext span:nth-child(7) {
    --i: 7;
}

.btext span:nth-child(8) {
    --i: 8;
}


.btext .hhh {
    color: red;
}


/**/

.btexts {
    position: absolute;
    bottom: 104px;
    left: 240px;
    font-family: "Open Sans", sans-serif !important;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: 1.1px;
}

.btexts span {
    display: inline-block;
    font-size: 45px;
    color: #2075b8;
    animation: flip 3s infinite;
    animation-delay: calc(.8s * var(--i))
}

@keyframes flip {

    0%,
    80% {
        transform: rotateY(340deg)
    }
}

.btexts span {
    --i: 1;
}

.btexts span:nth-child(2) {
    --i: 2;
}

.btexts span:nth-child(3) {
    --i: 3;
}

.btexts span:nth-child(4) {
    --i: 4;
}



.btexts .hhh {
    color: red;
}



.ptext {
    font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.2px;
    text-align: justify;
    width: 580px;
    color: rgb(36, 35, 35);
}
.banner button{
    position: absolute;
    top: 20px;
}

.banner button {
    border-radius: 4px;
    background-color: #5ca1e1;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 8px;
    width: 135px;
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
}

.banner button {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.banner button:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 7px;
    right: -20px;
    transition: 0.5s;
}

.banner button:hover {
    padding-right: 24px;
    padding-left: 8px;
}

.banner button:hover:after {
    opacity: 1;
    right: 10px;
}

@media (max-width: 1200px) {
    .banner .texts {
        bottom: 200px;
        left: 80px;
    }

    .btexts {
        bottom: 80px;
        left: 200px;
    }

    .ptext {
        width: 500px;
    }
}

@media (max-width: 992px) {
    .banner .texts {
        bottom: 300px;
        left: 60px;
    }

    .btexts {
        bottom: 60px;
        left: 150px;
    }

    .ptext {
        width: 400px;
    }

    .btext span,
    .btexts span {
        font-size: 35px;
    }

    .banner button {
        width: 120px;
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .banner .texts {
        bottom: 320px;
        left: 40px;
    }

    .btexts {
        bottom: 40px;
        left: 100px;
    }

    .ptext {
        width: 300px;
    }

    .btext span,
    .btexts span {
        font-size: 30px;
    }

    .banner button {
        width: 100px;
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .banner .texts {
        bottom: 400px;
        left: 20px;
    }

    .btexts {
        bottom: 20px;
        left: 60px;
    }

    .ptext {
        width: 250px;
    }

    .btext span,
    .btexts span {
        font-size: 25px;
    }

    .banner button {
        width: 90px;
        font-size: 10px;
    }
}

@media (max-width: 400px) {
    .banner .texts {
        bottom: 490px;
        left: 10px;
    }

    .btexts {
        bottom: 10px;
        left: 30px;
    }

    .ptext {
        width: 200px;
    }

    .btext span,
    .btexts span {
        font-size: 20px;
    }

    .banner button {
        width: 80px;
        font-size: 8px;
    }
}
/**/
.App{
    margin-top: 20px;
    margin-bottom: 90px;
    padding-left: 130px;
        padding-right: 130px;
        background-color: #fff;
       

}
.App .phone2{
    background-color: #f6f6f6;
    border-radius: 20px 0px 0px 20px;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}
.App .phone1 {
    background-color: #2075b8;
    border-radius: 0px 20px 20px 0px;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.App .phone1{
    padding-top: 80px;
        padding-bottom: 90px;
        position: relative;
}
.App .phone2 {
    padding-top: 80px;
    padding-bottom: 90px;
    position: relative;
}

.phone2 .phone img{
    height: 430px;
    width: 230px;
    position: absolute;
    left: 200px;
    bottom: 30px;
    z-index: 0.5;
}

.phone2 .arrow img{
    
    position: absolute;
    left: 120px;
    top: 180px;
    height: 100px;
    width: 370px;
}
.phone1{
    position: relative;
}
.phone1 .phead{
    display: flex;
    

}
/* .phon{
    color: red;
} */
.phone1 .phead img{
    height: 52px;
    width: 85px;
    padding-top: 5px;
    margin-top: 10px;
    
}
.phone1 .phead h5 {
    color: #fff;
    font-size: 20px;
    margin-left: 12px;
    margin-top: 13px;
}
.phone1 .star{
    margin-left: 94px !important;
}
.phone1 .star {
    position: absolute;
    top: 120px;
    
}
.phone1 svg{
    color: #ffc13c;
    margin-left: 2px;
    font-size: 27px;
}

.mobile{
    color: #fff;
    position: absolute;
    top: 230px;
    padding-left: 20px;
}

.ride1 button {
    position: absolute;
    top: 20px;
}

.mobile button {
    border-radius: 4px;
    background-color: #5f9ac7;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 8px;
    width: 165px;
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
}

.mobile button {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}


.mobile button:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 7px;
    right: -20px;
    transition: 0.5s;
}

.mobile button:hover {
    padding-right: 24px;
    padding-left: 8px;
}

.mobile button:hover:after {
    opacity: 1;
    right: 10px;
}


/**/


.ride{
    padding-left: 130px;
    padding-right: 130px;
    background-color: #f6f6f6;
    
}

.ride .ride1{
    padding-top: 80px;
    padding-bottom: 90px;
}
.ride .ride2 {
    padding-top: 80px;
    padding-bottom: 90px;
}
.ride1{
    width: 50%;

}
.ride1 h1{
    font-size: 35px;
}

.ride1 p{
    font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.2px;
        text-align: justify;
        width: 83%;

}
.subh1{
    color: #2075b8;
}
.subh2{
    color: red;
}

.ride2 img{
    height: 320px;
    width: 92%;
}


.ride1 button {
    position: absolute;
    top: 20px;
}

.ride1 button {
    border-radius: 4px;
    background-color: #5ca1e1;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 8px;
    width: 135px;
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
}

.ride1 button {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}


.ride1 button:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 7px;
    right: -20px;
    transition: 0.5s;
}

.ride1 button:hover {
    padding-right: 24px;
    padding-left: 8px;
}

.ride1 button:hover:after {
    opacity: 1;
    right: 10px;
}


/**/
#services{
    margin-top: 80px;
    margin-bottom: 80px;
}
#services h1{
    text-align: center;
    font-size: 35px;
}
#services .hh1{
    color: red;
}
#services .hh2 {
    color:#2075b8;
}
#services .paragraph {
    margin-top: 25px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: center;
    padding-left: 125px;  
    padding-right: 125px;
}

.qrcode{
    background-color: #f6f6f6;
   padding-top: 64px;
   padding-left: 110px;
   padding-bottom: 64px;
   padding-right: 110px; 
}
.qrcode h1{
    margin-left: 18px;
    font-size: 35px;
}
.qrcode .qr1{
    color:#2075b8;
}
.qrcode .qr2 {
    color: red;
}
.qrcode .card{
    position: relative;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
    height: 180px;
    max-width: 560px;
    margin-left: 25px;
    background-color:#fff;
    border-radius: 10px !important;

}
.card img{
    height: 170px;
    width: 170px;
}

.card .ctext{
    position: absolute;
    bottom: 42px;
    left: 170px;
    padding: 10px;
}

.ctext h2{
    font-size: 28px;
}
.ctext p{
    font-size: 18px;
}


.qrcode .card {
    border-radius: 4px;
    border: none;
    transition: all 0.5s;
    cursor: pointer;
}

.qrcode .card {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.qrcode .card:after {
    content: '»';
    color:red;
    font-size: 50px;
    position: absolute;
    opacity: 0;
    top: 42px;
    right: -10px;
    transition: 0.5s;
}

.qrcode .card:hover {
    padding-right: 24px;
}

.qrcode .card:hover:after {
    opacity: 1;
    right: 30px;
}


.css-y7mla4{
    text-align: center;
    width: 750px !important;
    
}

.card1 .card{
    position: relative;
    height: 180px;
    width: 100%;
    background-color: orange;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
    cursor: pointer;
    border: none;
}

.card1 .card .d1{
    height: 140px;
}
.card1 .card .d2 {
    height: 40px;
    background-color:#fff;
    width: 100%;
    padding: 5px 5px;
}
.card1 .card .d2 p {
    color: black;
}

.card1 .card .icon{
    position: absolute;
    top: 60px;
    left: 90px;

}
.card1 .card .icon svg{
    font-size: 35px;
    color: white;
}

/**/
#features{
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 80px;
    position: relative;
}
#features .fhead{
    text-align: center;
    font-size: 35px;
    padding-top: 80px;
}

#features .ff1{
    color: #2075b8;
}
#features .ff2 {
    color: red;
}

#features .fdesc{
    text-align: center;
}
.feature{
    padding-left: 33px;
    padding-right: 33px;
}
.feature .card{
    height: 350px;
    margin-top: 13px;
    margin-bottom: 2px;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    
    
}

.slider{
    padding-top: 30px;
}

.chead{
    text-align: center;
    padding: 8px;
    color: #ffffff;
    background-color:#2075b8;
    border-radius: 5px;
  
}
.chead h5{
    font-size: 18px !important;
    margin-top: 2px;
}

.cdesc{
    text-align: justify !important;
   margin-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    height: 50px;
}
.cdesc{
   
    font-size: 14px;
}
.cpic{
    height: 80px;
    position: relative;
    

}
.cpic img{
    height: 53px;
    width: 53px;
    position: absolute;
    left: 131px;
    top: 33px;
    padding: 2px;
}

.animation{
    
    content: '';
    position: absolute;
    width: 29%;
    height: 110%;
    border-radius: 50%;
    border: 3px dashed #dd8d8d;
    top: 20px;
    left: 113px;
    -webkit-animation: rotateEffect 15s linear infinite;
        animation: rotateEffect 15s linear infinite;
}
@keyframes rotateEffect {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/**/

/* .download{
        width: 100%;
            padding: 230px 0px 100px 0px;
            position: relative;
            position: relative;
            background-attachment: fixed;
            background-size: cover;
            background-image: url(assets/rideb.jpg);
} */


/**/


.pg-footer {
    font-family: 'Roboto', sans-serif;
}


.footer {
    background-color:#2075b8;
    color: #fff;
}

.footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
}

.footer-wave-path {
    fill:#f6f6f6;
}

.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
}

.footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
}

.footer-content-column ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-logo-link {
    display: inline-block;
}

.footer-menu {
    margin-top: 30px;
}

.footer-menu-name {
    color: #fffff2;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

.footer-menu-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
}

.footer-menu-list li {
    margin-top: 5px;
}

.footer-call-to-action-description {
    color: #fffff2;
    margin-top: 10px;
    margin-bottom: 20px;
}

.footer-call-to-action-button:hover {
    background-color: #fffff2;
    color: #00bef0;
}

.button:last-of-type {
    margin-right: 0;
}

.footer-call-to-action-button {
    background-color: #ffffff;
    border-radius: 21px;
    color:#2075b8;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: pointer;
    position: relative;
}

.footer-call-to-action {
    margin-top: 30px;
}

.footer-call-to-action-title {
    color: #fffff2;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

.footer-call-to-action-link-wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
}

.footer-call-to-action-link-wrapper a {
    color: #fff;
    text-decoration: none;
}





.footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer-social-amoeba-svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer-social-amoeba-path {
    fill:#5ca1e1;
}

.footer-social-link.linkedin {
    height: 26px;
    left: 3px;
    top: 11px;
    width: 26px;
}

.footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
}

.hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

.footer-social-icon-svg {
    display: block;
}

.footer-social-icon-path {
    fill: #fffff2;
    transition: fill .2s;
}

.footer-social-link.twitter {
    height: 28px;
    left: 62px;
    top: 3px;
    width: 28px;
}

.footer-social-link.youtube {
    height: 24px;
    left: 123px;
    top: 12px;
    width: 24px;
}

.footer-social-link.github {
    height: 34px;
    left: 172px;
    top: 7px;
    width: 34px;
}

.footer-copyright {
    border-top: 2px solid white;
    background-color:#2075b8;
    color: #fff;
    padding: 15px 30px;
    text-align: center;
}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.footer-copyright-text {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #fff;
    text-decoration: none;
}


/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px) {

    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 1050px;
        position: relative;
    }
}

@media (min-width:480px) and (max-width:599px) {

    /* smartphones, Android phones, landscape iPhone */
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 1050px;
        position: relative;
    }
}

@media (min-width:600px) and (max-width: 800px) {

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 1050px;
        position: relative;
    }
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */

}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */

}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */

}




@media (min-width: 760px) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 450px;
        position: relative;
    }

    .footer-wave-svg {
        height: 50px;
    }

    .footer-content-column {
        width: 24.99%;
    }
}

@media (min-width: 568px) {
    /* .footer-content-column {
      width: 49.99%;
  } */
}



/**/

.news{
    overflow-x: hidden;
    margin-bottom: 90px;
}
.news .nhead{
    text-align: center;
    
    color: #2075b8;

}

.news p{
    /* padding-bottom: 20px; */
    text-align: center;
    font-size: 17px;
    

}
.news .nred{
    color: red;
} 
.newz{
    padding-left: 10px;
}
.newz .card {
    margin: 5px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 345px;
    
}

.newz .card-header img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.newz .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 60px;
}

.newz .tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.newz .tag-teal {
    background-color:#2075b8;
}

.newz .tag-purple {
    background-color: #5e76bf;
}

.newz .tag-pink {
    background-color: #cd5b9f;
}

.newz .card-body p {
    font-size: 13px;
    margin: 0 0 3px;
}

.newz .user {
    display: flex;
    margin-top: auto;
}

.newz .user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.newz .user-info h5 {
    margin: 0;
}

.newz .user-info small {
    color: #545d7a;
}